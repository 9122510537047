<template>
    <main>           
        
        <div class="hero" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Niveles y beneficios</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Niveles y beneficios</h2>
                <div class="py-lg pt-0">
                    <p>Reclama tus puntos al comprar metros de tela Barrington, canjea premios y libera beneficios de acuerdo a tu nivel</p>
                </div>   
                <div class="table">
                    <table class="table__content">                        

                        <HeadLevel :dataLevel="dataLevel" :withLabel="false" />
                        
                        <tr>
                            
                            <td>Gana puntos por cada metro</td>
                            <td><router-link :to="{ name: 'HowEarnPoint' }">Desde 10 puntos</router-link></td>
                            <td><router-link :to="{ name: 'HowEarnPoint' }">Desde 12 puntos</router-link></td>
                            <td><router-link :to="{ name: 'HowEarnPoint' }">Desde 14 puntos</router-link></td>
                            <td><router-link :to="{ name: 'HowEarnPoint' }">Desde 16 puntos</router-link></td>
                            <td><router-link :to="{ name: 'HowEarnPoint' }">Desde 18 puntos</router-link></td>
                            
                        </tr>
                        
                        <tr v-for="(benefitlevel, key) in dataBenefitLevel" :key="key">
                            <td>{{ benefitlevel.name }}</td>                            
                                <td v-for="(item, key2) in benefitlevel.items" :key="key2">
                                    <img v-if="item.level__image_mark" :src="baseURLStatic + '/media/' + item.level__image_mark" alt="Club de Sastreria Barrington" height="48">
                                </td>                            
                        </tr>                        
                    </table>
                </div>
                <p class="text-center py-lg">Consulta todos los detalles de nuestros beneficios en los 
                    <router-link :to="{ name: 'TermsAndConditions' }">Términos y condiciones</router-link> del programa.</p>
            </div>
        </section>
        <Level />
    </main>       

</template>

<script>
import { ref, computed } from "vue";

import Level from "@/components/Level";
import HeadLevel from "@/components/HeadLevel";
import API from "@/api";

export default {
    name: 'LevelBenefit',
    components: {
        Level,
        HeadLevel,
    },
    setup(props, context) {
    
    const dataLevel = ref([])
    const dataStateLevel = ref(null)
    const dataBenefitLevel = ref([{
        items: [{}]
    }])
    const dataStateBenefitLevel = ref(null)
    const baseURL = API.defaults.baseURL
    const baseURLStatic = API.defaults.baseURLStatic
    

    const getBenefitLevel = () => {
        dataStateBenefitLevel.value = 'loading'
        API.get('/api/benefitlevel')
          .then(response => {
            dataStateBenefitLevel.value = 'success'
            dataBenefitLevel.value = response.data
            
          })
      }          
    
    const getLevel = () => {
        dataStateLevel.value = 'loading'
        API.get('/api/level')
          .then(response => {
            dataStateLevel.value = 'success'
            dataLevel.value = response.data
            
          })
      }

        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===1)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        } 
      
    return { dataBenefitLevel, dataLevel, getBenefitLevel, getLevel, baseURL, baseURLStatic, getHeaderDetail, formImagesItem, formImagesItemDefault, getDefault }

    },
    created () {
        this.getLevel()
        this.getBenefitLevel()                
    },
    mounted() {
        this.getDefault()
        this.getHeaderDetail()
    }
    
}
</script>

<style lang="postcss" scoped>
.backgroundSlide
{

}
</style>