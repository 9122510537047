<template>
    <main>
        <div class="hero" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Preguntas frecuentes</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Preguntas frecuentes</h2>
                
                <article class="faq">
                    

                        <h3>GENERAL</h3>

                        <div class="faq__answer">
                            <dl>
                                <dt>¿Qué es el CLUB BARRINGTON?</dt>
                                <dd>Es el primer programa de fidelización de BARRINGTON para sus clientes sastres y modistas. A través de él, accederán a fantásticos beneficios y podrán canjear grandes premios por sus compras en nuestras tiendas afiliadas.</dd>
                                <dt>¿Cómo formo parte del CLUB BARRINGTON?</dt>
                                <dd>Para formar parte del CLUB BARRINGTON, solo necesitas solicitar tu inscripción en cualquier tienda afiliada al programa. Luego deberás esperar a que tu cuenta haya sido validada por el equipo de Marketing de Barrington.</dd>
                                <dt>¿Por qué debería unirme al CLUB BARRINGTON?</dt>
                                <dd>Estarás registrado en nuestra base de datos oficial y recibirás los muestrarios físicos de nuestros productos; descuentos y beneficios exclusivos por cada nivel y
                                    acumularás puntos por tus compras en metros de tela para canjear grandes premios.</dd>
                                <dt>¿Cómo hago una consulta que no aparece en esta página?</dt>
                                <dd>Puedes escribirnos a nuestra dirección de correo electrónico: barrington@aris.com.pe</dd>
                                <dt>¿Cómo actualizo los datos de mi cuenta?</dt>
                                <dd>Puedes actualizar los datos de tu cuenta en tu perfil del CLUB BARRINGTON, guárdalos y tendrás una bonificación especial de puntos.</dd>
                                <dt>¿Cómo eliminar mi cuenta?</dt>
                                <dd>Para eliminar tu cuenta permanentemente por favor envíanos una solicitud a nuestra dirección de correo electrónico barrington@aris.com.pe desde tu email registrado en el programa.</dd>
                            </dl>             

                        </div>
                    
                </article>
                <article class="faq">
                    
                        <h3>MEMBRESIA Y BENEFICIOS:</h3>
                        <div class="faq__answer">
                            <dl>
                                <dt>¿Cómo se determina el nivel de membresía CLUB BARRINGTON?</dt>                            
                                <dd>El club cuenta con 5 niveles de membresía que se asignan automáticamente en función de los puntos obtenidos. Cada nivel libera nuevos beneficios y acelera tu acumulación de puntos.
                                <!--     <table cellspacing=0 class="table-fq">
                                        <tr>
                                            <td>CATEGORÍA</td>
                                            <td>BLUE</td>
                                            <td>BRONCE</td>
                                            <td>PLATA</td>
                                            <td>ORO</td>
                                            <td>DIAMANTE</td>
                                        </tr>
                                        <tr>
                                            <td>PUNTOS</td>
                                            <td>1 - 250</td>
                                            <td>251 - 600</td>
                                            <td>601 - 1200</td>
                                            <td>1201 - 1800</td>
                                            <td>1801 - 2400</td>
                                        </tr>
                                    </table> -->
                                </dd>
                                <dt>¿Dónde consigo mi tarjeta de membresía del CLUB BARRINGTON?</dt>
                                <dd>
                                    Puedes ver tu tarjeta de membresía virtual en tu perfil del CLUB BARRINGTON. En las tiendas afiliadas puedes identificarte como socio indicando tu número de documento de identidad.
                                </dd>
                                
                                <dt>¿Cuál es mi número de membresía del CLUB BARRINGTON?</dt>
                                <dd>Tu número de miembro para identificarte es tu DNI.</dd>

                                <dt>¿Cuáles son las telas que acumulan puntos?</dt>
                                <dd>Todas las telas de la marca BARRINGTON. <router-link :to="{ name: 'HowEarnPoint' }"> Ver tabla de equivalencias</router-link> </dd>
                                
                                <dt>¿Tengo algún descuento en productos por pertenecer al CLUB BARRINGTON?</dt>
                                <dd>Todos los socios cuentan con el precio sastre por menor y mayor.</dd>
                                
                                <!-- <dt>¿Cómo eliminar mi cuenta?</dt>
                                <dd>Para eliminar tu cuenta permanentemente por favor envíanos una solicitud a nuestra dirección de correo electrónico barrington@aris.com.pe desde tu email registrado en el programa</dd> -->
                            </dl>    
                        </div>
                    
                </article>
                <article class="faq">                    
                        <h3>ACUMULACIÓN DE PUNTOS:</h3>
                        <div class="faq__answer">
                            <dl>
                                <dt>¿Cómo se acumulan puntos en el CLUB BARRINGTON?</dt>
                                <dd>Para acumular puntos, el socio debe estar registrado y dado de alta en el CLUB BARRINGTON y comprar telas Barrington en tiendas afiliadas. El socio debe identificarse con su número de DNI.</dd>
                                
                                <dt>¿Cuántos puntos acumulo por mis compras?</dt>
                                <dd><router-link :to="{ name: 'HowEarnPoint' }">Ver tabla de equivalencias</router-link>. Cada metro de tela tiene una equivalencia de puntos distinta.</dd>
                                
                                <dt>¿Cuánto demora en aparecer la carga de puntos en mi cuenta?</dt>
                                <dd>Los puntos acumulados serán confirmados en el Historial de tu perfil en las 72 horas posteriores a la solicitud generada en la tienda.</dd>
                                
                                <dt>¿Cómo solicitar puntos faltantes en mi historial?</dt>
                                <dd>Antes de retirarte de la tienda, asegúrate que la solicitud de carga de puntos se haya registrado correctamente en tu perfil. Los puntos demoran hasta 72 horas en verse reflejados. Si no se han cargado, comunícate vía correo electrónico a barrington@aris.com.pe</dd>
                                
                                <dt>¿Mis puntos vencen?</dt>
                                <dd>No. Puedes hacer uso de tus puntos en cualquier momento.</dd>
                                
                                <!-- <dt>¿Cómo eliminar mi cuenta?</dt>
                                <dd>Para eliminar tu cuenta permanentemente por favor envíanos una solicitud a nuestra dirección de correo electrónico barrington@aris.com.pe desde tu email registrado en el programa</dd> -->
                            </dl>    
                        </div>
                    
                </article>
                <article class="faq">                    
                        <h3>CANJE DE PUNTOS:</h3>
                        <div class="faq__answer">
                            <dl>
                                <dt>¿Cómo puedo usar mis puntos?</dt>
                                <dd>Puedes usar tus puntos para canjear premios que serán anunciados en la misma web del CLUB BARRINGTON. Para conocer los términos y condiciones del programa por favor ingresa a: WEB.</dd>
                                
                                <dt>¿Cómo solicito el canje de un premio?</dt>
                                <dd>Para canjear tu premio, llena los datos que aparecen en el formulario “Zona de Canjes” en tu sesión de CLUB BARRINGTON y envía tu solicitud. Responderemos a tu email en un máximo de 72 horas, este plazo no toma en cuenta los días sábados y domingos. Para que puedas dar el canje por confirmado deberás recibir un mail con la aceptación de tu solicitud. Para conocer los términos y condiciones del programa por favor ingresa a: WEB</dd>
                                
                                <dt>¿Puedo comprar puntos?</dt>
                                <dd>Actualmente no contamos con la función para comprar puntos. Mantente atento a nuestras comunicaciones para saber de las próximas novedades del programa.</dd>
                                
                                <dt>Si me faltan puntos para completar un canje, ¿puedo pagar el equivalente que falta en dinero?</dt>
                                <dd>Actualmente no contamos con la función para combinar dinero y puntos.</dd>
                                
                                <dt>¿Puedo anular un canje de premio?</dt>
                                <dd>Puedes anular una reserva de canje premio que haya sido confirmada. Esta anulación se debe realizar vía correo electrónico a barrington@aris.com.pe. La anulación no tiene penalidad por lo que se te devolverán los puntos automáticamente.</dd>
                                                                
                            </dl>    
                        </div>
                    
                </article>

                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
    </main>
</template>

<script>

import router from "@/router"
import { ref, reactive } from "vue";
import API from "@/api";

export default {
    name: 'FrequentQuestions',
    setup() {


        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===6)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        } 

        return { router,  formImagesItem, formImagesItemDefault, getDefault, getHeaderDetail  }
    },
    async mounted() {
        this.getDefault()
        this.getHeaderDetail();
    }    
}
</script>