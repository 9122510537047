<template>
    <div class="banner">
        <section v-for="(item, key) in formAssetDataItems" :key="key" class="banner__item" :style="{ backgroundImage: `url(${location + item.image})` }">
            <div class="container banner__container">
                <div class="banner__text">
                    <h2>{{item.title}} <br>
                        {{item.subtitle}}
                    </h2>
                    <p>
                        {{item.description}}
                    </p>
                    <a :href="item.button_url" :target="item.button_target" ><button class="cta"  >{{item.button_text}}</button></a>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script>

import { ref } from "vue";
import API from "@/api";

function created(){
    this.getData();
}

export default {
    name: 'Banner',
    created,
    setup(){

        const formAssetErrors = ref({errors:{}})    
        const formAssetDataItems = ref([]);   
        const baseURLStatic = API.defaults.baseURLStatic
        const location = baseURLStatic//'https://services.barrington.com.pe';

        const getData = () => {      
            API.get('/api/banner-principal')
            .then(response => {      
                formAssetDataItems.value = response.data

            })
        }

        return { 
            formAssetDataItems,  
            formAssetErrors,  
            getData, 
            location,
        }

    }
}

</script>
