<template>
    <section class="section section--lighten">
        <div class="container">
            <div class="text-center">
                <img src="images/logo-vert.svg" width="280" alt="Club de Sastreria Barrington">
                
                <!-- <p class="mt-lg text-featured">Disfruta los Beneficios del CLUB DE LA SASTRERÍA BARRINGTON</p>                 -->

                <h1 class="mt-lg text-featured" >Disfruta los Beneficios del CLUB DE LA SASTRERÍA BARRINGTON</h1>                

            </div>
            <div class="grid grid--three-columns grid--gap-lg_front mt-lg">
                <div class="card">
                    <div class="square-icon_front">
                        <img src="images/icon-star.svg" alt="Club de Sastreria Barrington">
                    </div>
                    <h3>Niveles y Beneficios</h3>
                    <p>Te recompesamos con beneficios exclusivos de acuerdo a tu nivel.</p>
                    <router-link :to="{ name: 'LevelBenefit' }" class="link-read-more">
                        Conoce más
                    </router-link>
                    
                </div>
                <div class="card">
                    <div class="square-icon_front">
                        <img src="images/icon-card.svg" alt="Club de Sastreria Barrington">
                    </div>
                    <h3>Gana puntos</h3>
                    <p>Acumula puntos por tus compras de telas Barrington.</p>
                    <router-link :to="{ name: 'HowEarnPoint' }" class="link-read-more">
                        Conoce más
                    </router-link>
                </div>
                <div class="card">
                    <div class="square-icon_front">
                        <img src="images/icon-gift.svg" alt="Club de Sastreria Barrington">
                    </div>    
                    <h3>Canjea tus premios</h3>
                    <p>Usa tus puntos para canjear grandes premios.</p>
                    <router-link :to="{ name: 'HowRedeemPoint' }" class="link-read-more">
                        Conoce más
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:'LinkGrid',
}
</script>