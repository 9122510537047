
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import store from '@/store'
import VuePaginate from 'vue-paginate'
//Vue.use(VuePaginate)

const app = createApp(App).use(store).use(router).use(VuePaginate)

app.mount('#app')
