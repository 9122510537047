<template>
    <div>         
        <Header />
        <router-view/>        
        <template v-if="!neverFooter">
            <Footer v-if="!showFooter" />
            <FooterForPrivate v-if="showFooter" />
        </template>
    </div>
</template>

<script>

import { ref, computed } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer";
import FooterForPrivate from "@/components/FooterForPrivate";
import router from '@/router/index'
import { useRoute } from 'vue-router';
import { mapActions } from "vuex";

export default {
    name: 'Index',
    components: {
        Header,        
        Footer,
        FooterForPrivate,
    },
    setup(props, context) {

        const showFooter = computed(() => {            
            let excludeRouter = ['/privado/']                    
            let res = excludeRouter.map(x => useRoute().path.startsWith(x))            
            return res.includes(true)
            
        })
        const neverFooter = computed(() => {
            /* let arrayNeverRouter = ["/login"]
            let url = useRoute().path            
            let res = arrayNeverRouter.map(x => useRoute().path.startsWith(x))
            return res.includes(true) */
            let arrayNeverRouter = ["Login", "LinkSendEmailPassword"]            
            let router = useRoute()                        
            return arrayNeverRouter.includes(router.name)
            
        })
        return { showFooter, neverFooter, ...mapActions(['readToken']) }
    },    

    created(){
        
        this.readToken();
        
    }
    

}
</script>