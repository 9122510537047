<template>
    <header class="header">
        <div class="header__container">
            <router-link :to="{ name: 'Index'}" class="logo">
                <img src="/images/logo.svg" width="280" alt="Club de Sastreria Barrington" />
            </router-link>
            <button class="header__burger" id="burger">
                <span></span>
                <span></span>
                <span></span>
            </button>
            
            <nav class="header__nav">				
                <HeaderMenuDefault v-if="!store.state.dataToken.token" />                
                <HeaderMenuUser v-if="store.state.dataToken.token && ['CLODE', 'TAILO'].includes(store.state.dataToken.typeUser)" />                
                <HeaderMenuShop v-if="store.state.dataToken.token &&  store.state.dataToken.typeUser === 'SHOP'" />
                
            </nav>
            
            <br/>
            
            <router-link :to="{ name: 'Login' }" v-if="!store.state.dataToken.token">
                <button class="cta">                
                    <span class="icon-user"></span> <span>Iniciar sesión</span>                
                </button>            
            </router-link>
            
            
            <template v-if="store.state.dataToken.token && ['SHOP'].includes(store.state.dataToken.typeUser)">
                <router-link :to="{ name:'ShopRegisterPoint' }">
                    <button class="cta" v-if="store.state.dataToken.token">           
                        <span class="icon-user"></span> <span>Registrar puntos</span>
                    </button>            
                </router-link> 
                
            </template>
            <template v-else-if="store.state.dataToken.token && ['CLODE', 'TAILO'].includes(store.state.dataToken.typeUser)">
                <router-link :to="{ name:'RegisterPoint' }">
                    <button class="cta" v-if="store.state.dataToken.token">           
                        <span class="icon-user"></span> <span>Registrar puntos</span>
                    </button>            
                </router-link> 
            </template>    
            

        </div>
    </header>
</template>

<script>

import { ref } from "vue";
import store from '../store';
import { mapActions, mapState } from "vuex";
import HeaderMenuDefault from "../components/HeaderMenuDefault";
import HeaderMenuUser from "../components/HeaderMenuUser";
import HeaderMenuShop from "../components/HeaderMenuShop";
import Button from "../components/Button";

export default {
    name: "Header",
    components: {
        HeaderMenuDefault,
        HeaderMenuShop,
        HeaderMenuUser,
        
        
    },
    props: {
        msg: String
    },
    setup(){
        const showLogin = ref("")
        /* showLogin.value = store.state.token ? false: true
        console.log(store.state, 'STORE') */
        
        return { showLogin, store, ...mapActions(['closeSession']) }
    },
    
    

};
</script>