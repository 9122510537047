<template>
    <main>
        <Banner />
        <LinkGrid />
        <Level />
        <Exchange />
    </main>
</template>

<script>

import Banner from "@/components/Banner";
import LinkGrid from "@/components/LinkGrid";
import Level from "@/components/Level";
import Exchange from "@/components/Exchange";

export default {
  name: 'Index',
  components: {
      Banner,
      LinkGrid,
      Level,
      Exchange
  },
  data(){
     return {
         //data2: [0, 1]
     } 
  }

}
</script>
