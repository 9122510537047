<template>    
    <tr>
        <template v-if="withLabel">
            <th><div>Valor de puntos de<strong>1 metro por  nivel</strong></div></th>
        </template>
        <template v-else>
            <th>&nbsp;<br>&nbsp;</th>
        </template>                
        <th v-for="(level, key) in dataLevel" :key="key"><strong>{{ level.name }}</strong>                                                            
        <template v-if="key == 0">
            <span>0 - {{ dataLevel[key + 1].from_point - 1}} pts.</span>                                    
        </template>
        <template v-else-if="key <= 3">
            <span>{{ level.from_point }} - {{ dataLevel[key + 1].from_point - 1 }} pts.</span>                                     
        </template>
        <template v-else>
            <span>{{ level.from_point }} pts. a +</span>                                     
        </template>                                 
        </th>
    </tr>
</template>

<script>
export default {
    name: "HeadLevel",
    props: {
        dataLevel: Array,
        withLabel: Boolean
    }
}
</script>