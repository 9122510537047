<template>
    <ul class="header__menu">

        <li class="active"  v-if="!store.state.dataToken.token" >
            <router-link :to="{ name: 'Index' }"><span class="icon-home-outline"></span>Inicio</router-link>
        </li>
        <li>
            <a href="#"><span class="icon-cheveron-down"></span> Barrington Club de Sastrería</a>
            <ul class="header__submenu">
                <li v-for="(item, key) in formHeaderDetailItemsPermanent" :key="key">
                    <router-link :to="{ name: item.path, params: {id: item.id } }">{{item.name}}</router-link>
                </li>
                <li v-for="(item, key) in formHeaderDetailItemsNoPermanent" :key="key">
                    <a :href="item.url" :target="item.target">{{item.name}}</a>
                </li>               
            </ul>
        </li>
    </ul> 
</template>

<script>


import { ref } from "vue";
import API from "@/api";
import store from '../store';


function mounted(){
    this.getHeaderDetail();
}

export default {
    mounted,
    name: "HeaderMenuDefault",
    setup() {

        const formHeaderDetailItemsPermanent = ref([]);
        const formHeaderDetailItemsNoPermanent = ref([]);
        const responseHeaderDetailItemsPermanent = ref([]);
        
        const getHeaderDetail = () => {      
            API.get('/api/head-menu-navigation-detail')
            .then(response => {                      
                //debugger
                if (store.state.dataToken.token) {                    
                    responseHeaderDetailItemsPermanent.value = response.data.filter(x=> x.permanent && x.visible && x.path != 'RegisterUser');
                } else {                    
                    responseHeaderDetailItemsPermanent.value = response.data.filter(x=> x.permanent && x.visible && x.path);
                }
                
                formHeaderDetailItemsPermanent.value = responseHeaderDetailItemsPermanent.value
                formHeaderDetailItemsNoPermanent.value = response.data.filter(x=> !x.permanent && x.visible);
            })
        }
        
        return {
            getHeaderDetail,
            formHeaderDetailItemsPermanent,
            formHeaderDetailItemsNoPermanent,
            store
        }
    }
}
</script>