// Importamos Axios 
import axios from 'axios';
import store from '@/store'
require('dotenv').config()
// Definimos la ruta local del servidor Vue JS 

export default axios.create({
    /*LOCAL*/
    baseURL: process.env.VUE_APP_BASE_URL, //'http://127.0.0.1:8000',
    baseURLStatic: process.env.VUE_APP_BASE_URL_STATIC, //"http://127.0.0.1:8000",
    urlAdmin: process.env.VUE_APP_URl_ADMIN,//"http://localhost:8081",
    
    
    /* DEV VPS JHON */
    /* baseURL: "http://192.81.211.181/club-barrington",
    baseURLStatic: "http://192.81.211.181",
    urlAdmin: "http://admin-club-barrington.s3-website-us-east-1.amazonaws.com" */

    /* baseURL: "http://192.81.211.181/club-barrington",
    baseURLStatic: "http://192.81.211.181",
    urlAdmin: "http://192.81.211.181/admin", */
});

