<template>
    
    <footer class="footer section--darken pt-xl">
        <div class="container container--xs text-center">
            <h4>Acumula puntos por tus compras de telas Barrington</h4>
            <!-- <p :class="(getRouterCurrent())?'demo2':''">Si tu comercio aún no está afiliado al programa y quieres que tus sastres y modistas acumulen puntos por sus compras registra tu tienda ahora, pronto nos comunicaremos contigo</p> -->
            <p :class="{'space_affiliate': getRouterCurrent()}">Registra tu compra en nuestra red de tiendas afiliadas y acumula puntos! Podrás canjear grandes premios y gozarás de los beneficios del Club. Se parte de nuestro Club de Sastrería.</p>


            <p class="pt-lg" v-if="!getRouterCurrent()">
                <!-- <router-link class="cta cta--lighten" :to="{ name: 'AffiliateYourStore' }"><span class="icon-shop"></span> Afilia tu tienda</router-link> -->
                <!-- <a href="#" class="cta cta--lighten"><span class="icon-shop"></span> Afilia tu tienda</a> -->

            </p>
            <p>

                <router-link :to="{ name: 'AffiliateStores' }" class="cta cta--outline">Tiendas afiliadas</router-link> 
            </p>   
        </div>
        <div class="footer__copy">
            <div>
                <img :src="location + formAssetFooter.logo" class="footer__logo" alt="Club de Sastreria Barrington" width="200">
            </div>
            <div>
                <div class="footer__data"><a href="#"><span class="icon-phone"></span>  {{formAssetFooter.phone}} Ext:  {{formAssetFooter.extension}}</a> <a href="#"><span class="icon-mail"></span> {{formAssetFooter.email}}</a></div>
                <ul class="footer__socials">
                    <li v-if="formAssetFooter.facebook_flag"><a :href="formAssetFooter.facebook" :target="formAssetFooter.facebook_target"><span class="icon-facebook"></span></a></li>
                    <li v-if="formAssetFooter.instagram_flag"><a :href="formAssetFooter.instagram" :target="formAssetFooter.instagram_target"><span class="icon-instagram"></span></a></li>
                </ul>
                <div class="recopy">
                    <p>&reg; Todos los derechos reservados</p>
                    <ul class="footer__menu">
                        <li><a href="contacto.html">Contacto</a></li>
                        <li><router-link :to="{ name: 'FrequentQuestions'}">Preguntas frecuentes</router-link></li>
                        <li><router-link :to="{ name: 'TermsAndConditions'}">Términos y condiciones</router-link></li>
                        <li><router-link :to="{ name: 'PrivacyPolicies'}">Políticas de Privacidad</router-link></li>
                    </ul>
                </div>
            </div>
            
        </div>
    </footer>


</template>

<script>

import { useRoute } from 'vue-router';
import { ref } from "vue";
import API from "@/api"


export default {
    name: 'Footer',
    setup() {
        let router = useRoute()
        const getRouterCurrent = () => router.name == "AffiliateYourStore"
        const location = process.env.VUE_APP_BASE_URL;
        const formAssetFooter = ref({
            errors:[],
            logo: '',
            email: '',
            phone: '',
            extension: '',
            address: '',
            facebook: '',
            facebook_target: '_blank', 
            facebook_flag: false,
            instagram: '',
            instagram_target: '_blank',
            instagram_flag: false

        });

        const getFooter = () => {      
            API.get('/api/footer')
            .then(response => {      
                formAssetFooter.value = response.data[0]       

            })
        }


        return { getRouterCurrent, formAssetFooter, getFooter, location}
    },
    mounted() {
        this.getFooter()
    }


}
</script>