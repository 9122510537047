import { createStore } from 'vuex'
import API from "@/api";
import router from "@/router"

export default createStore({
    
    state: {
        //token: null,
        //typeUser: null,
        demo2: "jhon",
        dataToken: {},
        dataMessageErrorLogin: {},
        user: {
            level:{}, 
            point_next_level:[], 
            shop_that_register:{}
        },
        shop: {},
        urlAdmin: API.defaults.urlAdmin,
        conf: [{}],
        registerUserSuccess: {
            only_have_phone_movil: "",
            email: "",
            phone_movil: ""
        }

    },
    mutations: {
        setRegisterUserSuccess(state, payload) {
            //debugger
            state.registerUserSuccess = payload
        },
        setStateShop(state, payload) {
            state.shop = payload
        },
        setState(state, payload) {
            //debugger
            //state.token = payload.token
            //state.typeUser = payload.type_user
            state.dataToken.token = payload.access || payload.token
            state.dataToken.typeUser = payload.type_user
            state.dataToken.name = payload.name
            state.dataToken.address = payload.address
            state.dataToken.department = payload.department
            state.dataToken.country = payload.country
            state.dataToken.codeUser = payload.code_user
            state.dataToken.typeUserLabel = payload.type_user_label
            state.dataToken.id = payload.id
            state.dataToken.shop = payload.shop
            state.dataToken.total_point = payload.total_point
            state.dataToken.level = payload.level
            state.dataToken.level_label = payload.level_label
            state.dataToken.point_register = payload.point_register
            state.dataToken.photo = payload.photo
        },
        setStateMessageErrorLogin(state, payload) {
            //debugger
            state.dataMessageErrorLogin = payload
        },
        setStateUser(state, payload) {
            //debugger
            state.user = payload
        },
        setStateConf(state, payload) {
            state.conf = payload
        }
    },
    actions: {
        
        login({ commit }, user) {
            
            const res = API.post("/api/token/", user)
                .then(
                    response => {
                        //console.log(response.data)
                        //commit('setToken', response.data.access)
                        if (response.data.type_user == 'ADMIN') {
                            //debugger
                            console.log(API.defaults.urlAdmin, 'ADMIN')
                            window.location.replace(API.defaults.urlAdmin)
                        } else {
                            commit('setState', response.data)
                            localStorage.setItem('token', response.data.access)
                            localStorage.setItem('name', response.data.name)
                            localStorage.setItem('type_user', response.data.type_user)
                            localStorage.setItem('address', response.data.address)
                            localStorage.setItem('department', response.data.department)
                            localStorage.setItem('country', response.data.country)
                            localStorage.setItem('code_user', response.data.code_user)
                            localStorage.setItem('type_user_label', response.data.type_user_label)
                            localStorage.setItem('id', response.data.id)
                            localStorage.setItem('shop', response.data.shop)
                            localStorage.setItem('total_point', response.data.total_point)
                            localStorage.setItem('level', response.data.level)
                            localStorage.setItem('level_label', response.data.level_label)
                            localStorage.setItem('point_register', response.data.point_register)
                            localStorage.setItem('photo', response.data.photo)
                                                    
                            if (response.data.type_user == 'SHOP') {
                                return router.push({ name: 'ShopMyShop' })
                            }
                            if (['CLODE', 'TAILO'].includes(response.data.type_user)) {
                                return router.push({ name: 'UserStatus' })
                            }
                        }
                        
                        
                    }
                ).catch(error => {
                    
                    //console.log(error.response.data)
                    console.log(error.response)
                    commit('setStateMessageErrorLogin', error.response.data)
                    //state.dataMessageErrorLogin = error.response.data
                })

        },
        readToken({ commit }) {
            
            if (localStorage.getItem('token')) {
            
                //commit('setToken', localStorage.getItem('token'))
                //debugger

                commit('setState', localStorage)
            } else {
            
                //commit('setToken', null)
                commit('setState', {})
            }
        },

        closeSession({ commit }) {
            localStorage.removeItem('token')
            commit('setState', {})
            return router.push({ name: 'Index' })
        },

        getUser({ commit }, id) {
            API.get("/api/user/" + id)
                .then(response => {
                    console.log('READ!!')
                    commit("setStateUser", response.data)
                    //console.log('test', response.data)
                })
        },

        getConf({ commit }) {
            API.get("/api/conf")
                .then(response => {                    
                    //debugger
                    commit("setStateConf", response.data)                    
                })
        },

        getShop({ commit }, id) {
            //debugger
            API.get("/api/shop/" + id)
                .then(response => {
                    commit("setStateShop", response.data)
                })
        },
        postPointActivityDetail({ commit }, payload) {                    
            return API.post("/api/point-activity-detail", payload)                
        },
        getItemColor({ commit }, payload) {                    
            //let params = new URLSearchParams(payload).toString();
            //return API.get(`/api/item-color?${params}`)
            return API.get("/api/item-color-for-register-point")                
        },
        getShopRegisterPoint({ commit }, payload) {
            return API.get("/api/shop-register-point")
        }


    },
    modules: {
    }
})
