<template>    
    <section class="section pt-0 jhon">
        <div class="container">
            <div class="text-center">
                <h2>Canje de premios</h2>
                <p class="text-featured pb-xl">
                    Descubre todos los premios que tenemos para ti <br>
                y la cantidad de puntos que necesitas
                </p>
            </div>

            <div class="grid grid--three-columns grid--gap-lg mt-lg">
                <div href="#" class="home-prize" v-for="(v, k) in dataAssetExchange" :key="k">
                    <div class="home-prize__picture">
                        <img :src="`${baseURLStatic}${v.image}`" alt="Club de Sastreria Barrington">
                    </div>
                    <span class="home-prize__points">{{ v.point_exchange }} puntos</span>
                    <div class="home-prize__text">
                        <!-- <p>{{ v.description }}</p> -->
                        <p>Exclusivo membresia: {{v.level.name }}.</p>
                        <h3>{{ v.name }}</h3>
                    </div>
                </div>
                <!-- <a href="#" class="home-prize">
                    <div class="home-prize__picture">
                        <img src="/images/xbox.jpg" alt="">
                    </div>
                    <span class="home-prize__points">xxx puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: Estandar, Bronce.</p>
                        <h3>Descripción de razón social y alcance de beneficio</h3>
                    </div>
                </a>
                <a href="#" class="home-prize">
                    <div class="home-prize__picture">
                        <img src="/images/tshirt.jpg" alt="">
                    </div>
                    <span class="home-prize__points">xxxx puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: Estandar, Bronce.</p>
                        <h3>Descripción de razón social y alcance de beneficio</h3>
                    </div>
                </a>
                <a href="#" class="home-prize">
                    <div class="home-prize__picture">
                        <img src="/images/cellphone.jpg" alt="">
                    </div>
                    <span class="home-prize__points">xxx xxx xxx puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: Estandar, Bronce.</p>
                        <h3>Descripción de razón social y alcance de beneficio</h3>
                    </div>
                </a> -->
                
            </div>
            <br>
            <p class="text-center pt-xl" v-if="showLinkAssetExchange">
                <a href="#" class="link-read-more" @click.prevent="getDataAssetExchange()">Cargar más canjes</a>
            </p>
        </div>
    </section>
</template>

<script>

import { ref } from "vue";
import API from "@/api"

export default {
    name: 'Exchange',
    setup() {
        const baseURLStatic = API.defaults.baseURLStatic
        const dataAssetExchange = ref([])
        var flag = false
        var page = 1
        
        if (!flag){
            var limit = 3
            var limitFactor = 3            
            var incre = 1
        }
        
        const showLinkAssetExchange = ref(true)
        var countCompare = 0
        const getDataAssetExchange = () => {
            
            API.get("/api/asset-exchange" + "?limit=" + limit + "&page=" + page)
                .then(response => {
                    incre = incre + 1
                    limit = limitFactor * incre
                    flag = true                    
                    if (response.data.count <= response.data.results.length) {
                        showLinkAssetExchange.value = false                                                                    
                    }
                    countCompare = response.data.results.length                                        
                    dataAssetExchange.value = response.data.results
                })                
        }

        return {
            dataAssetExchange,
            getDataAssetExchange,
            baseURLStatic,
            showLinkAssetExchange,
        }
    },
    mounted() {
        this.getDataAssetExchange();
    }
}
</script>