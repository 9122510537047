<template>
	<ul class="header__menu">
		<li class="active">
			<router-link :to="{ name: 'Index' }"
				><span class="icon-home-outline"></span> Inicio</router-link
			>
		</li>

		<li class="menu-default-li">
            <HeaderMenuDefault :param1=false />
        </li>

		<li>
			<a href="#"
				><span class="icon-cheveron-down"></span>
				{{ store.state.dataToken.name.slice(0, count) + (store.state.dataToken.name.length > count ? "..." : "") }}
			</a>
			<ul class="header__submenu">
				<li>
                    <router-link :to="{ name: 'ShopMyShop' }">Mi tienda</router-link>					
				</li>
				<li>
                    <router-link :to="{ name: 'ShopRegisterUser' }">Registrar usuario</router-link>					
				</li>				
				<li>
					<a href="#" @click="closeSession">Cerrar sesión</a>
				</li>
			</ul>
		</li>
	</ul>
</template>

<script>
import store from "../store";
import { mapActions, mapState } from "vuex";
import HeaderMenuDefault from "@/components/HeaderMenuDefault"

export default {

	name: "HeaderMenuUser",
    components: {HeaderMenuDefault},
	setup() {
		const count = 15
        return { store, ...mapActions(['closeSession']), count }
    }
};
</script>