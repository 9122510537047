import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Index from '@/views/Index'
import LevelBenefit from '@/views/LevelBenefit'
import FrequentQuestions from "@/views/FrequentQuestions";
import store from '@/store';


const history = createWebHistory();
const router = createRouter({    
    history,
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('../views/Index') //import('../views/Offline')

        },
        {
            path: '/niveles-y-beneficios',
            name: 'LevelBenefit',
            component: LevelBenefit //de esta forma si carga el js necesario para el banner en movimiento
            //component: () => import("../views/LevelBenefit")
        },
        {
            path: '/como-ganar-puntos',
            name: 'HowEarnPoint',
            component: () => import('../views/HowEarnPoint')
        },
        {
            path: '/como-canjear-puntos',
            name: 'HowRedeemPoint',
            component: () => import('../views/HowRedeemPoint')
        },
        {
            path: '/afilia-tu-tienda',
            name: 'AffiliateYourStore',
            component: () => import('../views/AffiliateYourStore')
        },
        {
            path: '/tiendas-afiliadas',
            name: 'AffiliateStores',
            component: () => import('../views/AffiliateStores')
        },
        {
            path: '/preguntas-frecuentes',
            name: 'FrequentQuestions',
            component: FrequentQuestions
        },
        {
            path: '/afiliacion-exitosa',
            name: 'AffiliateSuccess',
            component: () => import('../views/AffiliateSuccess')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('../views/Login'),
        },
        {
            path: '/recuperar-contrasena',
            name: 'CredentialLinkRecovery',
            component: () => import('@/views/CredentialLinkRecovery'),
        },
        {
            path: '/nueva-contrasena/:hashString',
            name: 'LinkSendEmailPassword',
            component: () => import('@/views/LinkSendEmailPassword'),
        },
        {
            path: '/politicas-privacidad',
            name: 'PrivacyPolicies',
            component: () => import('@/views/PrivacyPolicies')
        },
        {
            path: '/terminos-y-condiciones',
            name: 'TermsAndConditions',
            component: () => import('@/views/TermsAndConditions')
        },
        {
            path: '/registro-usuario',
            name: 'RegisterUser',
            component: () => import('@/views/RegisterUser')
        },
        {
            path: '/registro-usuario-exito',
            name: 'RegisterUserSuccess',
            component: () => import('@/views/RegisterUserSuccess')
        },
        //zona tienda y usuarios

        {
            path: "/privado/usuario/registro-puntos-exito",
            name: 'RegisterPointSuccess',
            props: true,
            component: () => import('../components/RegisterPointSuccess'),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/usuario",
            name: 'UserStatus',
            component: () => import('../views/private/user/UserStatus'),
            //component: () => import("../views/private/shop/ShopMyShop"),            
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/usuario/mis-puntos",
            name: 'UserMyPoint',
            component: () => import('../views/private/user/UserMyPoint'),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/usuario/cuenta",
            name: 'UserAccount',
            component: () => import('../views/private/user/UserAccount'),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/usuario/tabla-equivalencia",
            name: 'UserTableEquivalence',
            component: () => import('../views/private/user/TableEquivalence'),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/usuario/registro-puntos",
            name: "RegisterPoint",
            component: () => import("../views/private/user/RegisterPoint"),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/tienda",
            name: "ShopMyShop",
            component: () => import("../views/private/shop/ShopMyShop"),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/tienda/registro-usuario",
            name: "ShopRegisterUser",
            component: () => import("../views/private/shop/ShopRegisterUser"),
            meta: {
                pathPrivate: true
            }
        },
        {
            path: "/privado/tienda/registro-puntos",
            name: "ShopRegisterPoint",
            component: () => import("../views/private/shop/ShopRegisterPoint"),
            meta: {
                pathPrivate: true
            }
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const pathIsPrivate = to.matched.some(record => record.meta.pathPrivate);
    //debugger
    //console.log(store, 'TOKEN')
    if (pathIsPrivate && store.state.dataToken.token === undefined) {

        next({ name: 'Login' })
    } else {

        next();
    }
})

export default router;

