<template>

    <ul class="header__menu">
        <li class="active">
            <router-link :to="{ name: 'Index' }"
                ><span class="icon-home-outline"></span>
                Inicio</router-link
            >
        </li>
        <li>
            <router-link :to="{ name: 'UserStatus' }">
                Mi Estado</router-link>
        </li>

        <li class="menu-default-li">
            <HeaderMenuDefault :param1=false />
        </li>
        
        <li class="profile-user-menu">
            <a href="#"
                ><span class="icon-cheveron-down"></span> 
                {{ showFooter }}<br><span class="point_header">Pts.: {{ store.state.user.total_point }}</span>                
            </a>
            <ul class="header__submenu">
                <li>
                    <router-link :to="{ name: 'UserMyPoint' }">Mis puntos</router-link>                    
                </li>
                <li>
                    <router-link :to="{ name: 'UserAccount' }">Mi cuenta</router-link>                    
                </li>
                <li>
                    <router-link :to="{ name: 'UserTableEquivalence' }">Tabla de equivalencias</router-link>                                            
                </li>
                <li>
                    <a href="#" @click="closeSession">Cerrar sesión</a>                    
                </li>                
            </ul>
        </li>
    </ul>
</template>

<script>

import store from '../store';
import { ref, computed } from "vue";
import HeaderMenuDefault from "@/components/HeaderMenuDefault"
import { mapActions } from "vuex"

export default {
    name: "HeaderMenuUser",    
    components: {HeaderMenuDefault},
    setup() {
        const showFooter = computed(() => { 
            return store.state.dataToken.name.split(' ')[0]
        })
        return { showFooter, store, ...mapActions(['closeSession']) }
    }
}
</script>