<template>
  <!-- <section class="section">
        <div class="container container--sm">
            <div class="text-center">
                <p class="text-featured">Libera más canjes y beneficios</p>
                <h2>Niveles a tu medida</h2>
            </div>
            <div class="home-levels">                
                <div v-for="(level, key) in dataLevel" :key="key">                
                    <div class="home-levels__item">
                        <div>
                            <img :src="baseURLStatic + level.image" alt="">
                        </div>
                        <div>
                            <h3> {{ level.name.toUpperCase() }}</h3>
                            <p>(<span>+</span> 
                                <template v-if="key == 0">{{ level.from_point }}</template><template v-else>{{ level.from_point - 1 }}</template> ptos)
                            </p>
                            <ul v-html="level.description"></ul>                            
                            <a href="#" class="link-read-more">Conoce más</a>
                        </div>
                    </div>
                </div>                                
            </div>
        </div>
    </section> -->

  <!-- Css Carousel-->
  <div class="carrousel">
    
    <input type="radio" name="slides" id="radio-1" checked />
    <input type="radio" name="slides" id="radio-2" />
    <input type="radio" name="slides" id="radio-3" />
    <input type="radio" name="slides" id="radio-4" />
    <input type="radio" name="slides" id="radio-5" />
    <div class="text-center" style="margin-bottom:60px">
        <p class="text-featured">Libera más canjes y beneficios</p>
        <h2>Niveles a tu medida.</h2>
    </div>
    <ul class="home-levels">
      <li class="slide" v-for="(level, key) in dataLevel" :key="key">
        <div class="home-levels__item">
            <div>
                <p>
                <!-- <q>It was a pleasure to work with him</q> -->                
                    <span class="author">
                        <img :src="baseURLStatic + level.image" alt="Club de Sastreria Barrington">                
                    </span>                            
                </p>
            </div>
            <div class="block-description">
                <h3> {{ level.name.toUpperCase() }}</h3>
                <p>(<span>+</span> 
                    <template v-if="key == 0">{{ level.from_point }}</template><template v-else>{{ level.from_point - 1 }}</template> ptos)
                </p>
                <ul v-html="level.description"></ul>                            
                <!-- <a href="#" class="link-read-more">Conoce más</a> -->
                <router-link :to="{ name: 'LevelBenefit' }">Conoce más</router-link>
            </div>
        </div>
      </li>
      <!-- <li class="slide">
        <p>
          <q>He is a good friend of mine</q>
          <span class="author">
            <img
              src="https://i.pinimg.com/736x/3f/c5/87/3fc587af121759209c53132a71c03c97--record-player-swing.jpg"
            />
            Sinatra
          </span>
        </p>
      </li>
      <li class="slide">
        <p>
          <q>This is pretty cool</q>
          <span class="author">
            <img
              src="https://pbs.twimg.com/profile_images/1832861297/GordonShumway12.jpg"
            />
            Alf
          </span>
        </p>
      </li>
      <li class="slide">
        <p>
          <q>This is awesome. Only Css you say?</q>
          <span class="author">
            <img
              src="http://www.claudiobernasconi.ch/wp-content/uploads/2014/03/github_octocat-300x300.jpg"
            />
            The octocat
          </span>
        </p>
      </li> -->
    </ul>
    <!-- <div class="slidesNavigation">
      <label for="radio-1" id="dotForRadio-1"></label>
      <label for="radio-2" id="dotForRadio-2"></label>
      <label for="radio-3" id="dotForRadio-3"></label>
      <label for="radio-4" id="dotForRadio-4"></label>
      <label for="radio-5" id="dotForRadio-5"></label>
    </div> -->
  </div>
</template>

<script>
import { ref, computed } from "vue";
import API from "@/api";

export default {
  name: "Level",

  setup(props, context) {
    const dataLevel = ref([]);
    const dataStateLevel = ref(null);
    const baseURL = API.defaults.baseURL;
    const baseURLStatic = API.defaults.baseURLStatic;

    const getLevel = async () => {
      dataStateLevel.value = "loading";
      return await API.get("/api/level").then(response => {
        dataStateLevel.value = "success";
        dataLevel.value = response.data;
      });
    };

    return { dataLevel, getLevel, dataStateLevel, baseURL, baseURLStatic };
  },
  created() {
    this.getLevel();
  }
};
</script>

<style scoped>
body {
  font-family: Helvetica;
  font-weight: 400;
  background: #ecf0f1;
}
h1 {
  font-size: 1.5em;
  text-align: center;
  margin: 1.2em 0;
  color: #555555;
}
h3 {
    font-weight: 400;
    color: #003f72;
    font-size: 2.5em;
    /* margin-top: -68px; */
    margin-top: 0px;
}
/*Carousel*/
.block-description {
    text-align: left;    
}
.carrousel {
  background: #ffffff;
  text-align: center;
  padding: 4em 0;
  height: 46.5em;
  max-width: 968px;
  margin: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 96px;
}
.carrousel h2 {
      margin: 0;
    /* margin-top: -1.7em; */
    padding: 0;
    font-size: 3em;
    text-align: center;
    color: #003f72;
}
.carrousel .slides {
  width: 502%;
  left: 0;
  padding-left: 0;
  padding-top: 1em;
  overflow: hidden;
  list-style: none;
  position: relative;

  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
}
.carrousel .slides li {
  width: 20%;
    position: relative;
    float: left;
    /* padding-left: 67px;
    padding-right: 67px; */
    left: 40px;
}




.carrousel li p {
  margin-top: 0;
    margin-right: 32px;
    font-size: 2em;
    letter-spacing: 8px;
}
.carrousel li q {
  max-width: 90%;
  margin: auto;
  color: #666666;
  font-size: 1.3em;
  font-weight: bold;
}
.carrousel li img {
  /* width: 3em;
  height: 3em;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -1.5em;
  margin-right: 0.5em;
  vertical-align: middle; */
}
.carrousel li span.author {
  margin-top: 0.5em;
  font-size: 1.2em;
  color: #777777;
  display: block;
}
.carrousel .slidesNavigation {
  display: block;
  list-style: none;
  text-align: center;
  bottom: 1em;
  /*--- Centering trick---*/
  /* Absolute positioning*/
  position: absolute;
  /* Abosulte positioning*/
  width: 112px; /*This width  is the addition of the width of all the navigations dots - So in this case is   104 because the navigation dots are 26px (width:10px and 6px marginleft + 6 px marginright) and there are 4 dots so 4x26=104 */
  left: 50%; /*Centering de element*/
  margin-left: -52px; /*adjusting the centering by applying a negative margin of half of the width*/
}
.carrousel input {
  display: none;
}
.carrousel .slidesNavigation label {
  float: left;
  margin: 6px;
  display: block;
  height: 10px;
  width: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 2px #2980b9;
  font-size: 0;
}
/* You have to repeat this with each slide
TODO: make it easier with SCSS
25% movement 100/slides-num
*/
#radio-1:checked ~ .slides {
  transform: translateX(0%);
}
#radio-2:checked ~ .slides {
  transform: translateX(-20%);
}
#radio-3:checked ~ .slides {
  transform: translateX(-40%);
}
#radio-4:checked ~ .slides {
  transform: translateX(-60%);
}
#radio-5:checked ~ .slides {
  transform: translateX(-80%);
}

.carrousel .slidesNavigation label:hover {
  cursor: pointer;
}
/* You have to repeat this with each slide/dot */
.carrousel #radio-1:checked ~ .slidesNavigation label#dotForRadio-1,
.carrousel #radio-2:checked ~ .slidesNavigation label#dotForRadio-2,
.carrousel #radio-3:checked ~ .slidesNavigation label#dotForRadio-3,
.carrousel #radio-4:checked ~ .slidesNavigation label#dotForRadio-4,
.carrousel #radio-5:checked ~ .slidesNavigation label#dotForRadio-5 {
  background: #2980b9;
}

@media (max-width: 796px) {
  .carrousel {
    height: 32.5em;
  }

}
@media (max-width: 480px) {
    .carrousel {
    height: 74.5em;
  }
  .carrousel li p {
    padding-left: 0.5em;
    padding-right: 0.5em;
    display: inline-flex;
  }
  .carrousel li q {
    font-size: 1em;
  }
  .carrousel li img {
    width: 100%;
    /* margin-left: -1em; */
    margin-right: 0.25em;
  }

  /* .carrousel .slides li {
    width: 20%;
        position: relative;
        float: left;
        padding-left: 1px;
        padding-right: 1px;
        left: 40px;
    }
 */
    .carrousel .slides li {
        width: 20%;
        position: relative;
        float: left;
        /* padding-left: 30px;
        padding-right: 30px; */
        left: 40px;
    }
    h3 {
        font-weight: 400;
        color: #003f72;
        font-size: 2.5em;
        margin-top: 0px;
    }
}
</style>