<template>
    
    <footer class="footer section--darken">
        
        <div class="footer__copy private">
            <div>
                <img :src="formAssetFooter.logo" class="footer__logo" alt="Club de Sastreria Barrington" width="200">
            </div>
            <div>
                <div class="footer__data"><a href="#"><span class="icon-phone"></span>  {{formAssetFooter.phone}} Ext:  {{formAssetFooter.extension}}</a> <a href="#"><span class="icon-mail"></span> {{formAssetFooter.email}}</a></div>
                <ul class="footer__socials">
                    <li v-if="formAssetFooter.facebook_flag"><a :href="formAssetFooter.facebook" :target="formAssetFooter.facebook_target"><span class="icon-facebook"></span></a></li>
                    <li v-if="formAssetFooter.instagram_flag"><a :href="formAssetFooter.instagram" :target="formAssetFooter.instagram_target"><span class="icon-instagram"></span></a></li>
                </ul>
                <div class="recopy">
                    <p>&copy; Todos los derechos reservados</p>
                    <ul class="footer__menu">
                        <li><a href="contacto.html">Contacto</a></li>
                        <li><a href="#">Preguntas frecuentes</a></li>
                        <li><a href="terminos-y-condiciones.html">Términos y condiciones</a></li>
                        <li><a href="politicas-de-privacidad.html">Políticas de Privacidad</a></li>
                    </ul>
                </div>
            </div>
            
        </div>
    </footer>


</template>

<script>

import { ref } from "vue";
import API from "@/api"

export default {
    name: 'FooterForPrivate',
    setup() {
        const formAssetFooter = ref({
            errors:[],
            logo: '',
            email: '',
            phone: '',
            extension: '',
            address: '',
            facebook: '',
            facebook_target: '_blank', 
            facebook_flag: false,
            instagram: '',
            instagram_target: '_blank',
            instagram_flag: false

        });

        const getFooter = () => {      
            API.get('/api/footer')
            .then(response => {      
                formAssetFooter.value = response.data[0]       

            })
        }

        return { formAssetFooter, getFooter }    
    },
    mounted() {
        this.getFooter()
    }
}
</script>